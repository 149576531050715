<template>
    <div class="quotes-holder">
        <div v-for="(item, index) in quotes" :key="`row-${index}`" :class="`row row-${index} stagger1`">
            <div class="quote" >“{{item.quote}}”</div>
            <div class="source" >&mdash;{{item.source}}</div>
        </div>
    </div>
</template>

<script>
import gsap, {Power3} from 'gsap/all'
import $ from "jquery"
export default {
    name: "QuotesHolder",
    props: {
        quotes: {
            type: Array,
            required: true
        },
    },
    data () {
        return {
            window: window,
            currentRow:0,
            tme:2,
            dist:50,
        }
    },
    mounted() {
        /*
        this.window.addEventListener('resize', this.onResize)
        this.onResize()
        let t = this
        t.slidesInit(2)
        setTimeout(function() {
            t.onResize()
        }, 1000)

         */
    },
    methods: {
        onResize() {
            console.log('onResize')
            let theHeight = 50
            $('.quotes-holder .row').each(function() {
                let h = $(this).height()
                if (h > theHeight) {
                    theHeight = h
                }
            })
            $('.quotes-holder').css({ height: theHeight})

            $('.quotes-holder .row').each(function() {
                let h = $(this).height()
                let top = (theHeight - h)/2
                $(this).css({ top:top })
                $(this).attr({datah: h})
            })

        },
        slidesInit(del=0) {
            let tl = gsap.timeline({
                onComplete: this.slides2
            })
            let sel = `.quotes-holder .row-${this.currentRow}`
            let scale = 1.1
            let blur = '10px'
            tl.fromTo(sel + ' .quote',
                {
                    opacity:0,
                    scale:scale,
                    filter: `blur(${blur})`
                },
                {
                    opacity: 1,
                    scale:1,
                    filter: `blur(0px)`,
                    duration: this.tme,
                    delay: del,
                    ease: Power3.easeOut
                },
                0
            )
            del += 0.05
            tl.fromTo(sel + ' .source',
                {
                    opacity:0,
                    scale:scale,
                    filter: `blur(${blur})`

                },
                {
                    opacity: 1,
                    scale:1,
                    filter: `blur(0px)`,
                    duration: this.tme,
                    delay: del,
                    ease: Power3.easeOut
                },
                0
            )

        },
        slides2() {
            let tl = gsap.timeline({
                //onComplete: this.slides3
            })
            let sel = `.quotes-holder .row-${this.currentRow}`
            let del = 4
            let ogDel = del
            let scale = 1.2
            let blur = '10px'
            tl.fromTo(sel + ' .quote',
                {
                    opacity:1,
                    scale: 1,
                    filter: `blur(0px)`
                },
                {
                    opacity: 0,
                    scale: scale,
                    filter: `blur(${blur})`,
                    duration: this.tme*1.25,
                    delay: del,
                    ease: Power3.easeOut
                },
                0
            )
            del += 0.05
            tl.fromTo(sel + ' .source',
                {
                    opacity: 1,
                    scale: 1,
                    filter: `blur(0px)`
                },
                {
                    opacity: 0,
                    scale: scale,
                    filter: `blur(${blur})`,
                    duration: this.tme*1.25,
                    delay: del,
                    ease: Power3.easeOut
                },
                0
            )

            //ADVANCE NEXT SLIDE
            this.currentRow++
            if (this.currentRow>=this.quotes.length ) {
                this.currentRow = 0
            }
            let t = this

            let finalDelay = (ogDel * 1000) + 0
            setTimeout(function() {
                t.slidesInit(0)
            }, finalDelay)
        }
    }
}
</script>


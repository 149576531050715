<template>
    <section id="section-home">
        <div id="main-bg-image" class="bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg.jpg') + `)`">

        </div>
        <div class="con">

            <div class="panel-outer">
                <div class="panel">
                    <div class="panel2">
                        <div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>
                        <div class="dates stagger1">
                            <div class="l-small style2">AVAILABLE <span class="nobreak">ON DEMAND</span></div>
                            <div class="l-small">
                                <button @click="openAction">
                                    watch now
                                </button>
                            </div>
                            <!--
                            <div class="l-big">NOW PLAYING</div>
                            -->
                            <!--
                            <div class="l-small">
                                <button @click="openAction">
                                    watch now on demand
                                </button>
                            </div>
                            -->
                        </div>


                        <button class="ytplayer stagger1 Zdeactivated" @click="gh.openOverlay('video')">
                            <div class="bg">
                                <img class="bg-frame" src="@/assets/images/home/player.jpg">
                            </div>
                            <div class="icon stagger1">
                                <div class="icon2">
                                    <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
                                </div>
                            </div>
                        </button>
                        <div class="l3 style2 stagger1">Sign up for updates & exclusive content.</div>
                        <div class="l3 signup stagger1">
                            <EmailSignup
                                mcDomain="https://lullabymovieofficial.us20.list-manage.com"
                                mcUserId="b25b645d846865015940afb4d"
                                mcListId="f713ffe268"
                            ></EmailSignup>
                        </div>
                        <div class="l3 button-row stagger1">
                            <div class="button-row-l">
                                <span>#HuntHerKillHerMovie</span>
                            </div>
                            <div class="button-row-r">
                                <a href="https://www.dropbox.com/sh/as44zo5geaiiway/AABX9aUa9Att9OLX2ZElIFgFa?dl=0" target="_blank" class="btn-carrot full-width">
                                    <span>PRESS/EPK</span>
                                </a>
                            </div>
                        </div>
                        <div class="l3 left-side-icons stagger1">
                            <div class="social-icons">
                                <div class="icon studio">
                                    <a href="https://welcomevillain.com" target="_blank">
                                        <img src="@/assets/images/home/logo-welcome-villain.png"/>
                                    </a>
                                </div>
                                <div class="icon studio">
                                    <a href="https://traumaoneentertainment.com/" target="_blank">
                                        <img src="@/assets/images/home/logo-trauma-one.png"/>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://twitter.com/welcomevillain" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/twitter.svg')"/>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://www.facebook.com/WelcomeVillain/" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/facebook-f.svg')"/>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://www.instagram.com/welcomevillain/" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/instagram.svg')"/>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://www.youtube.com/channel/UCDteF1bU5KPODkda_0j_VbA?sub_confirmation=1" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/youtube.svg')"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-side">
                    <div class="quotes-holder-outer">
                        <QuotesHolder :quotes="quotes"></QuotesHolder>
                    </div>
                    <div class="accolades">
                        <div class="accolades2 stagger1">
                            <img src="@/assets/images/home/accolade-1.png"/>
                            <img src="@/assets/images/home/accolade-2.png"/>
                            <img src="@/assets/images/home/accolade-3.png"/>
                            <img src="@/assets/images/home/accolade-4.png"/>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div id="order" :class="[(isOpen) ? 'open' : '']">
            <div @click="closeAction" class="scrim-closer"></div>
            <div class="order-inner">
                <div class="order-inner2">
                    <div class="bgimg"
                         :style="`background-image: url(` + require('@/assets/images/popup/tt-bg.jpg') + `)`">
                    </div>
                    <div class="tt">
                        <img src="@/assets/images/popup/tt-popup.png"/>
                    </div>

                    <div class="bugs">
                        <button class="bug">
                            <a href="https://www.amazon.com/Hunt-Her-Kill-Natalie-Terrazzino/dp/B0B787663L/ref=mp_s_a_1_2?crid=3PG45TJ1KFS2U&keywords=hunt+her+kill+her+movie&qid=1681455849&sprefix=hunt+her+kill+her%2Caps%2C132&sr=8-2"
                               target="_blank">
                                <img src="@/assets/images/order/amazon.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://play.google.com/store/movies/details/Hunt_Her_Kill_Her?id=tmjQJPZY5ck.P"
                               target="_blank">
                                <img src="@/assets/images/order/google.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.vudu.com/content/movies/details/Hunt-Her-Kill-Her/2298785" target="_blank">
                                <img src="@/assets/images/order/vudu.png"/>
                            </a>
                        </button>

                        <button class="bug">
                            <a href="https://tv.apple.com/movie/hunt-her-kill-her/umc.cmc.65mip7ogttx47v7ksexcrz1q1?itsct=tv_box_link&itscg=30200" target="_blank">
                                <img src="@/assets/images/order/apple-tv-ca.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://tv.apple.com/movie/hunt-her-kill-her/umc.cmc.65mip7ogttx47v7ksexcrz1q1?itsct=tv_box_link&itscg=30200" target="_blank">
                                <img src="@/assets/images/order/apple-tv.svg"/>
                            </a>
                        </button>

                        <!--
                        <button class="bug">
                            <a href="https://www.regmovies.com/movies/hunt-her-kill-her/ho00013977#/" target="_blank">
                                <img src="@/assets/images/order/regal.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.amctheatres.com/movies/hunt-her-kill-her-71905" target="_blank">
                                <img src="@/assets/images/order/amc.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.fandango.com/hunt-her-kill-her-2023-231039/movie-overview?date=2023-03-02"
                               target="_blank">
                                <img src="@/assets/images/order/fandango.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.laemmle.com/film/hunt-her-kill-her#get-tickets"
                               target="_blank">
                                <img src="@/assets/images/order/lamelle.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.readingcinemasus.com/rohnertpark/film/hunt-her-kill-her"
                               target="_blank">
                                <img src="@/assets/images/order/reading.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.consolidatedtheatres.com/kaahumanu"
                               target="_blank">
                                <img src="@/assets/images/order/consolidated.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href=" https://lumierecinemala.com/film-hunt-her-kill-her/"
                               target="_blank">
                                <img src="@/assets/images/order/lumiere.png"/>
                            </a>
                        </button>
                        -->
                    </div>
                    <div class="hash">
                        #HuntHerKillHerMovie
                    </div>
                </div>
            </div>
            <div @click="closeAction" class="close-button-outer">
                <div class="close-button"></div>
            </div>
        </div>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
import EmailSignup from '@/components/blocks/EmailSignup'
import QuotesHolder from '@/components/blocks/QuotesHolder'

export default {
    name: "HomePage",
    components: {
        EmailSignup,
        QuotesHolder
    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
            quotes: [
                {
                    quote: "Exceptionally tense and unnerving",
                    source: "MaxDoesMovies"
                },
                {
                    quote: "An unrelenting cat and mouse chase",
                    source: "Meagan Navarro, BloodyDisgusting"
                },
                {
                    quote: "Brutal and Intense",
                    source: "Janel Spiegel, HorrorNews.Net"
                },

            ]
        }
    },
    mounted() {
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    methods: {
        escapeButtonPressed(e) {
            if (e.key === "Escape") {
                this.closeAction()
            }
        },
        openAction() {
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        }
    }
}
</script>